<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card class="elevation-1">
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('label.cliente', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          v-if="metadadosCliente != null"
          :metadados-entidade="metadadosCliente"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="cliente"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          ref="container">
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_codigo"
              name="campo_codigo"
              v-model="cliente.idExterno"
              :label="`${$tc('label.cod_cliente', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_nome"
              name="campo_nome"
              v-model="cliente.nomCliente"
              :label="`${$tc('label.nome', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_cnpj"
              name="campo_cnpj"
              v-model="cliente.desCnpj"
              :label="`${$tc('label.cnpj_cpf', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              vCpfCnpj
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_nome"
              name="campo_contato_nome"
              v-model="cliente.contato.nomContato"
              :label="`${$tc('label.contato', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_email"
              name="campo_contato_email"
              v-model="cliente.contato.vlrEmail"
              :label="`${$tc('label.email', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_telefone"
              name="campo_contato_telefone"
              v-model="cliente.contato.vlrTelefone"
              :label="`${$tc('label.telefone', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="20"
              maxlength="20"
              v-mask="'(##) #####-####'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_banco"
              name="campo_banco"
              v-model="cliente.nomBanco"
              :label="$tc('label.banco', 1)"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_agencia"
              name="campo_agencia"
              v-model="cliente.vlrAgencia"
              :label="$tc('label.agencia', 1)"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_conta"
              name="campo_conta"
              v-model="cliente.vlrConta"
              :label="$tc('label.conta', 1)"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-switch
              color="primary"
              :label="$t('label.ativo')"
              v-model="cliente.indAtivo"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-switch
              color="primary"
              :label="$t('label.sap_pagador')"
              v-model="cliente.indPagador"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-autocomplete
              id="campo_tipo_beneficio"
              name="campo_tipo_beneficio"
              v-model="cliente.tipoBeneficio"
              :disabled="somenteLeitura"
              :items="tiposBeneficio"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="descTipoBeneficio"
              item-value="id"
              :label="`${$tc('label.tipos_pagamentos_aceitos', 1)}`"
              clearable
              return-object
              @click.native="buscarTiposBeneficio">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-autocomplete
              id="campo_regiao"
              name="campo_regiao"
              v-model="cliente.regiao"
              :disabled="somenteLeitura"
              :items="regioes"
              :search-input.sync="buscaListaRegioes"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="nomRegiao"
              item-value="id"
              :label="`${$tc('label.regiao', 1)}`"
              clearable
              return-object
              @click.native="buscarRegioes">
            </v-autocomplete>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="btn_cancelar_novo_cliente" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn id="btn_salvar_novo_cliente" v-if="!somenteLeitura" @click.native="openSaveDialog" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogCadastroExtensao" persistent max-width="800px">
        <cliente-nova-extensao
          ref="clienteNovaExtensao"
          :somenteLeitura="somenteLeitura"
          :objeto="novaExtensao"
          @cancelar="fecharCadastroExtensao"
          @atualizarGrid="setarCadastroExtensao"
        />
      </v-dialog>
    </v-card>

    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('label.cliente', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { skipLoading } from '../../common/functions/loading';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import Confirm from '../../shared-components/vuetify/dialog/Confirm';
import ClienteNovaExtensao from './ClienteNovaExtensao';

export default {
  name: 'ClienteForm',
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MetadadosContainerLayout,
    Confirm,
    ClienteNovaExtensao,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      resourceCliente: this.$api.cliente(this.$resource),
      fetchTipoBeneficioFunction: this.$api.cliente(this.$resource).buscarTiposBeneficio,
      fetchRegiaoFunction: this.$api.regiao(this.$resource).listarAtivos,
      buscaListaRegioes: null,

      cliente: {
        desCnpj: '',
        tipoPessoa: 'JURIDICA',
        id: null,
        idContato: null,
        indAtivo: true,
        indPagador: false,
        nomBanco: '',
        nomContato: '',
        vlrAgencia: '',
        vlrConta: '',
        contato: {
          vlrTelefone: '',
        },
      },
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      tiposBeneficio: [],
      regioes: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      dialogCadastroExtensao: false,
      novaExtensao: {},
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getClienteMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
    ]),
    metadadosCliente() {
      if (this.getClienteMetadado && this.getClienteMetadado.mapaEntidades) {
        return this.getClienteMetadado;
      }
      return undefined;
    },
  },
  watch: {
    buscaListaRegioes(val) {
      if (this.cliente && this.cliente.idRegiao) {
        const selecao = this.regioes
          .filter((item) => item.id === this.cliente.idRegiao);
        if (selecao.length > 0 && selecao[0].nomRegiao === val) {
          return;
        }
      }

      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (val != null) this.buscarRegioes(val);
      }, 500);
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setClienteMetadado',
    ]),
    carregarCliente() {
      const { id } = this.$route.params;
      skipLoading();
      this.resourceCliente.buscar({ id })
        .then((res) => {
          this.cliente = { ...this.cliente, ...res.body };
          if (this.cliente.idTipoBeneficio) {
            this.definirTipoBeneficio();
          }
          if (this.cliente.idRegiao) {
            this.definirRegiao();
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarTiposBeneficio() {
      return this.fetchTipoBeneficioFunction()
        .then((res) => {
          this.tiposBeneficio = res.data.resposta;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    definirTipoBeneficio() {
      this.buscarTiposBeneficio().then(() => {
        const [tipoBeneficio] = this.tiposBeneficio
          .filter((t) => t.id === this.cliente.idTipoBeneficio);
        this.cliente.tipoBeneficio = tipoBeneficio;
        this.$forceUpdate();
      });
    },
    definirRegiao() {
      this.buscarRegioes().then(() => {
        const [regiao] = this.regioes
          .filter((t) => t.id === this.cliente.idRegiao);
        this.cliente.regiao = regiao;
        this.$forceUpdate();
      });
    },
    buscarRegioes(busca = null) {
      const param = {
        nom_regiao: busca,
        tamanhoPagina: 50,
        numeroPagina: 0,
      };
      return this.fetchRegiaoFunction(param)
        .then((res) => {
          this.regioes = res.data.resposta;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    openSaveDialog() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    openCancelarDialog() {
      if (this.somenteLeitura) {
        this.cancelar();
        return;
      }
      this.$refs.cancelarDialog.open();
    },
    salvar() {
      this.prepararClienteParaSalvar();
      const objArmazenamento = {
        ...this.cliente,
        ...this.$refs.container.getValoresDependencias(),
      };

      if (this.cliente.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.inserir(objArmazenamento);
      }
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    inserir(obj) {
      this.exibeAlertaLgpd();
      this.resourceCliente.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: this.$route.params.from || 'cliente' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      this.exibeAlertaLgpd();
      const { id } = obj;
      this.resourceCliente.atualizar({ id }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$router.push({ name: this.$route.params.from || 'cliente' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    prepararClienteParaSalvar() {
      if (this.cliente.regiao) {
        this.cliente.idRegiao = this.cliente.regiao.id;
      }
      if (this.cliente.tipoBeneficio) {
        this.cliente.idTipoBeneficio = this.cliente.tipoBeneficio.id;
      }
      this.cliente.tipoPessoa = this.cliente.desCnpj.replace(/[^0-9]/g, '').length === 11
        ? 'FISICA'
        : 'JURIDICA';
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'cliente' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.$refs.clienteNovaExtensao.setNivel(metadados.idNivelExtensao);
      this.dialogCadastroExtensao = true;
    },
    fecharCadastroExtensao() {
      this.dialogCadastroExtensao = false;
    },
    setarCadastroExtensao(obj, metadados) {
      if (metadados.manyToMany) {
        if (!this.cliente[metadados.label]) {
          this.cliente[metadados.label] = [];
        }
        this.cliente[metadados.label].push(obj);
      } else {
        this.cliente[metadados.label] = obj;
      }
      this.$refs.container.refresh();
      this.dialogCadastroExtensao = false;
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      if (this.getClienteMetadado && this.getClienteMetadado.mapaEntidades) {
        this.carregarCliente();
      } else {
        this.setClienteMetadado({
          resource: this.metadadoResource.listarCliente,
          params: null,
          callback: this.carregarCliente,
        });
      }
    }
  },
};
</script>
